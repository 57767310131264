exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-links-js": () => import("./../../../src/templates/links.js" /* webpackChunkName: "component---src-templates-links-js" */),
  "component---src-templates-post-single-js": () => import("./../../../src/templates/post-single.js" /* webpackChunkName: "component---src-templates-post-single-js" */),
  "component---src-templates-posts-archive-blog-js": () => import("./../../../src/templates/posts-archive-blog.js" /* webpackChunkName: "component---src-templates-posts-archive-blog-js" */),
  "component---src-templates-posts-archive-js": () => import("./../../../src/templates/posts-archive.js" /* webpackChunkName: "component---src-templates-posts-archive-js" */),
  "component---src-templates-publications-js": () => import("./../../../src/templates/publications.js" /* webpackChunkName: "component---src-templates-publications-js" */)
}

